<template>
  <div class="h-100">
      <TheHeader/>
      <div class="c-body h-100">
        <main class="c-main h-100 p-0">
          <CContainer class="h-100 p-0">
            <transition name="fade" mode="out-in">
              <b-overlay :show="loading" class="h-100">
                <router-view :key="$route.path"></router-view>

                <template #overlay>
                  <msi-spinner />
                </template>
              </b-overlay>
            </transition>
          </CContainer>
        </main>
      </div>
  </div>
</template>

<script>
import { CContainer } from '@coreui/vue';
import { BOverlay } from 'bootstrap-vue';
import { get } from 'vuex-pathify';

import MsiSpinner from '@/components/MsiSpinner.vue';
import TheHeader from './TheHeader';

export default {
  name: 'TheContainer',
  components: {
    CContainer,
    BOverlay,
    MsiSpinner,
    TheHeader
  },
  computed: {
    loading: get('site/loading'),
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.c-body {
  padding-top: 57px;
}
</style>
