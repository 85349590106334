<template>
  <CHeader fixed with-subheader light>
    <CHeaderBrand class="ml-3" to="/">
      <img src="img/brand/msi_logo.svg" height="24" alt="Logo"/>
    </CHeaderBrand>

    <div class="ml-auto mr-4 d-flex align-items-center" v-if="isUserAuthenticated">
      <h6 class="d-none d-sm-block mb-0 mr-2">Welcome {{ userName }}</h6>
      <b-button size="sm" variant="primary" @click="handleLogout()">Logout</b-button>
    </div>
  </CHeader>
</template>

<script>
import { CHeader, CHeaderBrand } from '@coreui/vue';
import { BButton } from 'bootstrap-vue';

export default {
  name: 'TheHeader',
  components: {
    CHeader,
    CHeaderBrand,
    BButton
  },
  data() {
    return {
      isUserAuthenticated: false,
      userName: ''
    };
  },
  methods: {
    handleLogout() {
      this.$auth.logout();
    }
  },
  async created() {
    if (this.$auth.authenticated) {
      this.isUserAuthenticated = true;
      this.userName = this.$auth.tokenParsed.given_name;
    }
  }
};
</script>
